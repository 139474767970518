<template>
    <div class="col-12" v-if="collections !== null && collections.length>0">
        <card-slider :title="title" :data="collections" card="reiseplan" />
    </div>
</template>

<script>
import { extractMerklisten } from '@/utils/tripmeister-helpers';
import { getCollectionsWithContent } from '@/api/collection';

export default {
    name: 'CollectionBlock',
    components: {
        CardSlider: () => import('@/components/CardSlider.vue'),
    },
    props: {
        content: {
            type: Object,
        default: null
        },
    },
    data() {
        return {
            collections: null,
        }
    },
    computed: {
        text(){
            if(this.content!==null){
                switch(this.content.content_type_name){
                case "angebot":
                    return "Dieses Angebot";
                    break;
                case "institution":
                    return "Dieser Ort";
                    break;
                default:
                    return "Dieser Ort";                    
                    break;
                }
            }
        },
        title(){
            return this.text + " ist in folgenden Reiseplänen vertreten:";
        }
    },
    created() {
      this.getCollections();
  }, 
  methods: {
     getCollections() {
        if(this.content!==null){
            getCollectionsWithContent(this.content.id).then(response => {
                const { data } = response;
                this.collections = extractMerklisten(data,5);
            })
        }
    },
}
}
</script>

